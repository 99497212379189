import React from 'react'

import prijslijst from '../assets/images/prijslijst.jpeg';

const Prices = () => {
  return (
    <div className="page page--prices">
      <div className="container">
        <div className="prices-wrapper">
          <img src={prijslijst} alt="pétanco21-prijslijst" />
          
        </div>
      </div>
    </div>
  )
}

export default Prices
