import React, { useState, useEffect } from "react";
import { RiArrowRightCircleFill } from "react-icons/ri";

const CountdownBanner = () => {
  const [daysLeft, setDaysLeft] = useState(0);
  const [hoursLeft, setHoursLeft] = useState(0);
  const [minutesLeft, setMinutesLeft] = useState(0);
  const [secondsLeft, setSecondsLeft] = useState(0);

  let interval;

  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;

  const getCountdown = () => {
    const countDate = new Date("August 10, 2024 09:00:00").getTime();

    interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const timeLeft = countDate - currentTime;

      if (timeLeft <= 0) {
        clearInterval(interval.current);
      } else {
        setDaysLeft(Math.floor(timeLeft / day));
        setHoursLeft(Math.floor((timeLeft % day) / hour));
        setMinutesLeft(Math.floor((timeLeft % hour) / minute));
        setSecondsLeft(Math.floor((timeLeft % minute) / second));
      }
    }, 1000);
  };

  useEffect(() => {
    getCountdown();
  });

  return (
    <div className="countdown-banner">
      <a
        href="https://www.facebook.com/events/839688128056704?active_tab=about"
        target="_blank"
        rel="noreferrer"
        className="container"
      >
        <h3>Pétanco '24</h3>
        <div className="timer-wrapper">
          <div className="timer-item day-wrapper">
            <span className="days">{daysLeft}</span>
            <p>days</p>
          </div>
          <div className="timer-item hour-wrapper">
            <span className="hours">{hoursLeft}</span>
            <p>hours</p>
          </div>
          <div className="timer-item minute-wrapper">
            <span className="minutes">{minutesLeft}</span>
            <p>minutes</p>
          </div>
          <div className="timer-item second-wrapper">
            <span className="seconds">{secondsLeft}</span>
            <p>seconds</p>
          </div>
        </div>
        <div className="button-wrapper">
          <p>Meer info</p>
          <RiArrowRightCircleFill />
        </div>
      </a>
    </div>
  );
};

export default CountdownBanner;
