import React from 'react'
import { Link } from 'react-router-dom';

import { SponsorBanner } from '../components';

import logo from '../assets/images/leeuwen-logo-txt.png'
import * as Routes from '../routes';

const Footer = () => {
  return (
    <footer>
      <div className="footer-logo">
        <Link to={Routes.HOME}>
          <img src={logo} alt="de-leeuwen-logo-footer" />
        </Link>
      </div>
      <div className="socials">
        <ul>
          <li><a href="https://www.facebook.com/d.leeuwen" target="_blank" rel="noreferrer">facebook</a></li>
          <li><a href="https://www.instagram.com/de_leeuwen_/" target="_blank" rel="noreferrer" >instagram</a></li>
          <li><a href="mailto: deleeuwen@outlook.be">email</a></li>
        </ul>
      </div>
      <SponsorBanner />
    </footer>
  )
}

export default Footer
