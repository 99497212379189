import React, { useState } from 'react'

import { Carousel } from 'react-bootstrap'

const Slideshow = ({sliderData}) => {

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} >
      {sliderData.map(item => (
        <Carousel.Item key={item.id}>
        <img
          src={item.url}
          alt="item.alt"
        />
      </Carousel.Item>
    ))}
    </Carousel>
  )
}

export default Slideshow
