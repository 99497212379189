import React from 'react'
import { Link } from 'react-router-dom'

import { ImageTextBlock } from '../components'
import { gql, useQuery } from '@apollo/client';

import * as Routes from '../routes';

const EventsPage = () => {

  const EVENTSPAGE = gql `
  query {
    pages(where: {pageTitle: "Events"}) {
      pageTitle
      blocks {
        __typename
        ... on ImageTextBlock {
          id
          backgroundColor
          imageUrl {
            url
          }
          imageAltText
          imageAlignment
          imageSize
          title
          text {
            html
          }
          ctaLabel
          ctaSlug
      }
      }
    }
  }
  `;

  const { data } = useQuery(EVENTSPAGE, {
    fetchPolicy: 'cache-first',
  });

  if(data) {

    const EventsPageContent = data.pages[0]
    const { blocks } =  EventsPageContent;

    return (
      <div className="page page--events">
        <div className="container">
          <h1 className="title-element">Events</h1>
          <div className="breadcrumbs">
            <ul>
              <li><Link to={Routes.HOME} >Home</Link></li>
              <li className="active">Events</li>
            </ul>
          </div>
        </div>
        {blocks ?
          blocks.map(block => (
            <ImageTextBlock
              key={block.id}
              background={block.backgroundColor}
              alignment={block.imageAlignment}
              imageSpace={block.imageSize}
              imageUrl={block.imageUrl.url}
              imageAlt={block.imageAltText}
              title={block.title}
              text={block.text.html}
              ctaLabel={block.ctaLabel}
              ctaRoute={block.ctaSlug}
            />
          ))
        : null
        }
      </div>
    )
  } else {
    return (
      <div className="page page--events">
        <div className="container">
          <p>Loading...</p>
        </div>
        
      </div>
    )
  }
}

export default EventsPage