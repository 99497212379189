import React from 'react'
import { CtaButton } from '../../components';

const TextBlock = ({background, title, text, textAlign, ctaLabel, ctaRoute}) => {

  const getRichText = (stringData) => {
    return {__html: stringData}
  }

  return (
    <>
    {background ?
      <div className="text-block text-block-bg" style={{textAlign: textAlign}}>
        <div className="container">
          <h2>{title}</h2>
          <div className="text-container" dangerouslySetInnerHTML={getRichText(text)}></div>
          {ctaLabel && ctaRoute ? <CtaButton label={ctaLabel} route={ctaRoute}/> : null}
        </div>
    </div>
    :
    <div className="text-block" style={{textAlign: textAlign}}>
      <div className="container">
        <h2>{title}</h2>
        <div className="text-container" dangerouslySetInnerHTML={getRichText(text)}></div>
        {ctaLabel && ctaRoute ? <CtaButton label={ctaLabel} route={ctaRoute}/> : null}
      </div>
    </div>
    }
    </>
  )
}

export default TextBlock;
