import { gql, useQuery } from "@apollo/client";
import React from "react";
import { Link } from "react-router-dom";
import Faq from "../components/Blocks/Faq";

import * as Routes from "../routes";

const FaqPage = () => {
  const FAQBLOCKS = gql`
    query FaqBlocks {
      faqBlocks {
        id
        question
        answer
      }
    }
  `;

  const { data } = useQuery(FAQBLOCKS, {
    fetchPolicy: "cache-first",
  });

  if (data) {
    return (
      <div className="page page--faq">
        <div className="container">
          <div className="breadcrumbs">
            <ul>
              <li>
                <Link to={Routes.HOME}>Home</Link>
              </li>
              <li className="active">FAQ</li>
            </ul>
          </div>

          <div className="questions">
            {data?.faqBlocks ? (
              data?.faqBlocks.map((fb) => (
                <Faq key={fb.id} question={fb.question} answer={fb.answer} />
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="page page--about">
        <div className="container">
          <p>Loading...</p>
        </div>
      </div>
    );
  }
};

export default FaqPage;
