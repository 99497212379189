import React from 'react'
import { Link } from 'react-router-dom';
import { DesktopNav, MobileNav } from '../components';

import * as Routes from '../routes';
import Logo from '../assets/images/leeuwen-logo-txt.png';

const Header = () => {
  return (
    <header>
      <div className="logo-wrapper">
        <Link to={Routes.HOME}>
          <img src={Logo} alt="leeuwen-logo" />
        </Link>
      </div>
      <div className="nav-wrapper">
        <DesktopNav />
        <MobileNav />
      </div>
    </header>
  )
}

export default Header;
