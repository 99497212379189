import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";

import { Header, Footer, CountdownBanner } from "./components";
import { HomePage, AboutPage, EventsPage, Prices, FaqPage } from "./pages";
import { ScrollToTop } from "./helpers";
import * as Routes from "./routes";

import "../app/scss/main.scss";

const App = () => {
  const client = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHCMS_URI,
    cache: new InMemoryCache(),
  });

  return (
    <div className="App">
      <ApolloProvider client={client}>
        <Router>
          <ScrollToTop />
          <CountdownBanner />
          <Header />
          <Switch>
            <Route exact path={Routes.HOME} component={HomePage} />
            <Route exact path={Routes.ABOUT} component={AboutPage} />
            <Route exact path={Routes.EVENTS} component={EventsPage} />
            <Route exact path={Routes.PRICES} component={Prices} />
            <Route exact path={Routes.FAQ} component={FaqPage} />
          </Switch>
          <Footer />
        </Router>
      </ApolloProvider>
    </div>
  );
};

export default App;
