import React from "react";
import { gql, useQuery } from "@apollo/client";

import { Slideshow, VideoBlock, ImageTextBlock } from "../components";

const HomePage = () => {
  const HOMEPAGE = gql`
    query {
      pages(where: { pageTitle: "Home" }) {
        blocks {
          __typename
          ... on ImageCarousel {
            carouselItems {
              id
              url
            }
          }
          ... on VideoBlock {
            video {
              url
            }
            videoLabel
            ctaLabel
            videoCtaSlug
          }
          ... on ImageTextBlock {
            backgroundColor
            imageUrl {
              url
            }
            imageAltText
            imageAlignment
            imageSize
            title
            text {
              html
            }
            ctaLabel
            ctaSlug
          }
        }
      }
    }
  `;

  const { data } = useQuery(HOMEPAGE, {
    fetchPolicy: "cache-first",
  });

  if (data) {
    const HomePageContent = data.pages[0].blocks;
    const { carouselItems } = HomePageContent[0];
    // Video Block
    const videoBlock = HomePageContent[1];
    const { video, videoLabel, ctaLabel, videoCtaSlug } = videoBlock;
    // Text + Image Block
    const imageTextBlock = HomePageContent[2];
    const {
      backgroundColor,
      imageUrl,
      imageAltText,
      imageAlignment,
      imageSize,
      title,
      text,
      ctaLabel: buttonLabel,
      ctaSlug,
    } = imageTextBlock;

    return (
      <>
        <div className="page page--home">
          <div className="carousel-wrapper">
            <Slideshow sliderData={carouselItems} />
          </div>

          <VideoBlock
            video={video.url}
            label={videoLabel}
            ctaLabel={ctaLabel}
            ctaRoute={videoCtaSlug}
          />

          <ImageTextBlock
            background={backgroundColor}
            alignment={imageAlignment}
            imageSpace={imageSize}
            imageUrl={imageUrl.url}
            imageAlt={imageAltText}
            title={title}
            text={text.html}
            ctaLabel={buttonLabel}
            ctaRoute={ctaSlug}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="page page--home">
          <p>Loading....</p>
        </div>
      </>
    );
  }
};

export default HomePage;
