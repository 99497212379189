import React from 'react'
import { Link } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import { TextBlock, ImageGrid } from '../components';

import * as Routes from '../routes';

 const AboutPage = () => {

  const ABOUTPAGE = gql `
  query {
    pages(where: {pageTitle: "About"}) {
      blocks {
        __typename
        ...on TextBlock {
          backgroundColor
          title
          text {
            html
          }
          textAlignment
        }
        ...on Gallery {
          members {
            ...on Member {
              name
              picture {
                url
              }
            }
          }
        }
      }
    }
  }
  `;

  const { data } = useQuery(ABOUTPAGE, {
    fetchPolicy: 'cache-first',
  });

  if(data) {

    const AboutPageContent = data.pages[0].blocks;
    // TextBlock
    const TextBlockContent = AboutPageContent[0];
    const { backgroundColor, title, text, textAlignment } = TextBlockContent;
    // GalleryBlock
    const GalleryBlock = AboutPageContent[1];
    const { members } = GalleryBlock;

    return (
      <div className="page page--about">
        <div className="container">
          <div className="breadcrumbs">
            <ul>
              <li><Link to={Routes.HOME} >Home</Link></li>
              <li className="active">Over ons</li>
            </ul>
          </div>
        </div>
        <TextBlock
        background={backgroundColor}
        title={title}
        text={text.html}
        textAlign={textAlignment}
        />
        <ImageGrid data={members}/>
      </div>
    )
  } else {
    return (
      <div className="page page--about">
        <div className="container">
          <p>Loading...</p>
        </div>
      </div>
    )
  }
}

export default AboutPage;
