import React from 'react'
import { Link } from 'react-router-dom'

const CtaButton = ({label, route}) => {
  return (
    <div className="cta-button-wrapper">
      <Link to={`/${route}`}>{label}</Link>
    </div>
  )
}

export default CtaButton
