import React from 'react'

const MemberCard = ({image, name}) => {
  return (
    <div className="member-card">
      <img src={image} alt={name} />
      <div className="card-overlay">
        <p>{name}</p>
      </div>
    </div>
  )
}

export default MemberCard
