
import React, { useState } from 'react';

import { NavigationLinks } from '../../components';

import { FaBars, FaTimes } from 'react-icons/fa'

const MobileNav = () => {

  const [open, setOpen] = useState(false);

  const closeMobileNav = () => setOpen(false);

  return (
    <>
      {open ?
        <div className="close-burger animate__animated animate__fadeIn">
          <FaTimes onClick={() => setOpen(!open)}/>
          {/* <i className="fas fa-times" id="close-icon" onClick={() => setOpen(!open)}></i> */}
        </div>
      : 
        <div className="burger">
          <FaBars onClick={() => setOpen(!open)}/>
          {/* <i className="fas fa-bars" id="burger-icon" onClick={() => setOpen(!open)}></i> */}
        </div>
      }
    {open ?
    <nav className="mobile-navigation animate__animated animate__slideInRight">
      <NavigationLinks isMobile={true} closeNav={closeMobileNav} /> 
    </nav>
    : null }
    </>
  );
}

export default MobileNav;