  
import React from 'react';

import { NavigationLinks } from '../../components';

const DesktopNav  = () => {
  return (
    <nav className="desktop-navigation">
      <NavigationLinks />
    </nav>
  );
}

export default DesktopNav;