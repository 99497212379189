import React from "react";
import { Link } from "react-router-dom";

import * as Routes from "../../routes";

const NavigationLinks = ({ isMobile, closeNav }) => {
  return (
    <ul>
      <li onClick={() => isMobile && closeNav()}>
        <Link to={Routes.HOME}>Home</Link>
      </li>
      <li onClick={() => isMobile && closeNav()}>
        <Link to={Routes.ABOUT}>Over ons</Link>
      </li>
      <li onClick={() => isMobile && closeNav()}>
        <Link to={Routes.EVENTS}>Events</Link>
      </li>
      <li onClick={() => isMobile && closeNav()}>
        <Link to={Routes.FAQ}>FAQ</Link>
      </li>
    </ul>
  );
};

export default NavigationLinks;
