import React from 'react'
import { MemberCard } from '../components';

const ImageGrid = ({data}) => {

  return (
    <div className="image-grid">
      <div className="container">
        <div className="row">
          {data.map(member => (
            <div className="col-6 col-md-6 col-lg-3 col-xl-3 member-col" key={member.name}>
              <MemberCard image={member.picture.url} name={member.name}/>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ImageGrid;
