import React from 'react';
import { gql, useQuery } from '@apollo/client';

const SponsorBanner = () => {

  const SPONSORBANNER = gql `
    query {
      sponsorBanners {
        sponsors {
          ...on Sponsor {
            sponsorName
            sponsorLogo {
              url
            }
          }
        }
      }
    }
  `;

  const { data } = useQuery(SPONSORBANNER, {
    fetchPolicy: 'cache-first',
  });

  if(data) {

    const sponsorBanner = data.sponsorBanners[0]; 
    const { sponsors } = sponsorBanner;
    
    return (
      <div className="container banner-container">
        <div className="sponsor-banner">
          {sponsors.map(sponsor => (
            <div className="sponsor-card" key={sponsor.sponsorName}>
              <img src={sponsor.sponsorLogo.url} alt={sponsor.sponsorName} />
            </div>
          ))}
        </div>
      </div>
    );
  } else return null

}

export default SponsorBanner;
