import React from 'react'

import { CtaButton } from '../../components'

const VideoBlock= ({video, label, ctaLabel, ctaRoute}) => {

  return (
    <div className="video-block">
      <div className="container">
        <div className="video-wrapper">
          <video width="100%" height="100%" autoPlay muted loop controls={true}>
            <source src={video} type="video/mp4"/>
          </video>
        </div>
        { label ? <h1 className="title-element">{label}</h1> : null}
        
        
      </div>
        {ctaLabel && ctaRoute ? <CtaButton label={ctaLabel} route={ctaRoute}/> : null}
    </div>
  )
}

export default VideoBlock
