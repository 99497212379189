import React from 'react'

import { CtaButton } from '../../components';

const ImageTextBlock = ({background, alignment, imageSpace, imageUrl, imageAlt, title, text, ctaLabel, ctaRoute}) => {

  const imageSize = imageSpace;
  const textSize = 12 - imageSize;

  const getRichText = (stringData) => {
    return {__html: stringData}
  }

  return (
    <>
    {background ?
      <div className="image-text-block image-text-block-bg">
        <div className="container">
          <div className={`row image-${alignment}`}>
            <div className={`col-12 col-md-12 col-lg-${imageSize} col-xl-${imageSize} image-col`}>
              <div className="image-wrapper">
                <img src={imageUrl} alt={imageAlt} />
              </div>
            </div>
            <div className={`col-12 col-md-12 col-lg-${textSize} col-xl-${textSize} text-col`}>
              <h2>{title}</h2>
              <div className="text-container" dangerouslySetInnerHTML={getRichText(text)}></div>
              {ctaLabel && ctaRoute ? <CtaButton label={ctaLabel} route={ctaRoute}/> : null}
            </div>
          </div>
        </div>
      </div>
    :
      <div className="image-text-block">
        <div className="container">
          <div className={`row image-${alignment}`}>
            <div className={`col-12 col-md-12 col-lg-${imageSize} col-xl-${imageSize} image-col`}>
              <div className="image-wrapper">
                <img src={imageUrl} alt={imageAlt} />
              </div>
            </div>
            <div className={`col-12 col-md-12 col-lg-${textSize} col-xl-${textSize} text-col`}>
              <h2>{title}</h2>
              <div className="text-container" dangerouslySetInnerHTML={getRichText(text)}></div>
              {ctaLabel && ctaRoute ? <CtaButton label={ctaLabel} route={ctaRoute}/> : null}
            </div>
          </div>
        </div>
      </div>
    }
      
    </>
  )
}

export default ImageTextBlock;
