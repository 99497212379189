import React from "react";

const Faq = ({ question, answer }) => {
  return (
    <div className="faq-block">
      <h3>{question}</h3>
      <p>{answer}</p>
    </div>
  );
};

export default Faq;
